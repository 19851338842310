<template>
  <div class="formbiaodan">
    <el-form v-model="form" label-width="130px" label-position="left">
      <el-input type="hidden" style="width:300px" placeholder="请输入名称" v-model="form.id"></el-input>

      <el-row>
        <el-col :span="12">
          <el-form-item>
              <span slot="label">
                   <span class="text-danger">
                    <i class="el-icon-star-off"></i>
                </span>
                  BUG问题模块:
              </span>
            <el-select v-model="form.moduleid" style="width:300px" placeholder="请选择">
              <el-option
                v-for="item in bugmodules"
                :key="item.id"
                :label="item.module_name"
                :value="item.mid"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name">
              <span slot="label">
                   <span class="text-danger">
                    <i class="el-icon-star-off"></i>
                </span>
                  技术员:
              </span>
            <el-select v-model="form.fixmanid" style="width:300px" placeholder="请选择技术员">
              <el-option
                v-for="item in fixmans"
                :key="item.id"
                :label="item.man_name"
                :value="item.fid"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-show="solvshow" :span="24">
          <el-form-item prop="name">
              <span slot="label">
                <span class="text-danger">
                    <i class="el-icon-star-off"></i>
                </span>
                处理情况:
            </span>
            <el-select v-model="form.solve" style="width:300px" placeholder="请选择">
              <el-option
                v-for="item in editsolves"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <span slot="label">
                <span class="text-danger">
                    <i class="el-icon-star-off"></i>
                </span>
                BUG内容:
            </span>
          </el-form-item>
            <Editor :content="editcontent" @change="editchange" ref="wangE" />
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer mt-3">
      <el-button @click="qxsubmit">取 消</el-button>
      <el-button type="primary" @click="formsubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/common/wanedit";

export default {
  data() {
    return {
      form: {
        id: "",
        moduleid: "",
        fixmanid: "",
        text: "",
        image: [],
        solve: "0",
      },
      fixmans: [],
      bugmodules: [],
      editcontent: "",
      solvshow: false,
      solves: [
        {
          value: 3,
          label: "全部",
        },
        {
          value: 0,
          label: "未解决",
        },
        {
          value: 1,
          label: "已解决",
        },
        {
          value: 2,
          label: "搁置",
        },
      ],
      subtype: "",
      editsolves: [
        {
          value: 0,
          label: "未解决",
        },
        {
          value: 1,
          label: "已解决",
        },
        {
          value: 2,
          label: "搁置",
        },
      ],
    };
  },
  components: {
    Editor,
  },
  mounted() {
    let type = this.$route.query.type;
    this.subtype = type;
    this.restartform();
    if (type == "edit") {
      let data = this.$route.query.datas;
      this.solvshow = true;
      this.form.id = data.id;
      this.form.moduleid = parseInt(data.moduleid);
      this.form.fixmanid = parseInt(data.fixmanid);
      this.editcontent = data.text;
      this.form.text = data.text;
      this.form.image = data.img;
      this.$store.state.dict.image["BUG_image"] = data.img;
      this.form.solve = parseInt(data.sovlenum);
    }
    this.getset();
  },
  methods: {
    formsubmit() {
      if (this.form.moduleid == "" || this.form.fixmanid == "") {
        this.$message.error("必填项为空！请填写必填项...");
      } else {
        this.form.image = this.$store.state.dict.image["BUG_image"];
        let that = this;
        this.$axios
          .post("/bug/index/add", JSON.parse(JSON.stringify(that.form)))
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功...");
              this.$store.state.dict.image["BUG_image"] = [];
              setTimeout(() => {
                this.$router.push({
                  path: "/bug/list",
                });
              }, 500);
            } else {
              this.$message.error("服务器打盹了,请稍后重试.....");
            }
          });
      }
    },
    qxsubmit() {
      this.$router.push({
        path: "/bug/list",
      });
    },
    restartform() {
      this.editcontent = "";
      this.form.id = "";
      this.form.moduleid = "";
      this.form.leavel = "";
      this.form.fixmanid = "";
      this.form.text = "";
      this.form.image = [];
      this.solve = -1;
    },
    getset() {
      let that = this;
      this.$axios.post("/bug/index/getset").then((res) => {
        if (res.code == 200) {
          that.fixmans = res.data.fixmans;
          that.bugmodules = res.data.modules;
        } else {
          this.$message.error("服务区打盹了,请稍后重试.....");
        }
      });
    },
    editchange(val) {
      this.form.text = val;
      if (this.$store.state.dict.image["BUG_image"] !== 0) {
        let nowimgs = this.getSrc(val);
        this.$store.state.dict.image["BUG_image"] = nowimgs;
      }
    },
    /**
     * 取出区域内所有img的src
     */
    getSrc(html) {
      var imgReg = /<img.*?(?:>|\/>)/gi;
      // 匹配src属性
      var srcReg = /<img.*?src="(.*?)".*?\/?>/i;
      var arr = html.match(imgReg);

      let imgs = [];
      if (arr) {
        for (let i = 0; i < arr.length; i++) {
          var src = arr[i].match(srcReg)[1];
          imgs.push({
            url: src,
          });
        }
      }

      return imgs;
    },
  },
};
</script>
<style scoped>
.formbiaodan {
  padding-left: 20px;
}
</style>